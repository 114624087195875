import React, { Component } from "react";
import AktieDataService from "../services/aktien.service";
import { withRouter } from '../common/with-router';


class Aktie extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSymbol = this.onChangeSymbol.bind(this);
    this.getAktie = this.getAktie.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateAktie = this.updateAktie.bind(this);
    this.deleteAktie = this.deleteAktie.bind(this);

    this.state = {
      currentAktie: {
        id: null,
        title: "",
        description: "",
        published: false
      },
      message: ""
    };
  }

  componentDidMount() {
    this.getAktie(this.props.router.params.id);
  }

  onChangeName(e) {
    const sName = e.target.value;

    this.setState(function(prevState) {
      return {
        currentAktie: {
          ...prevState.currentAktie,
          Name: sName
        }
      };
    });
  }

  onChangeSymbol(e) {
    const sSymbol = e.target.value;

    this.setState(prevState => ({
      currentAktie: {
        ...prevState.currentAktie,
        Symbol: sSymbol
      }
    }));
  }

  getAktie(id) {
    AktieDataService.get(id)
      .then(response => {
        this.setState({
          currentAktie: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updatePublished(status) {
    var data = {
      id: this.state.currentAktie.id,
      title: this.state.currentAktie.title,
      description: this.state.currentAktie.description,
      published: status
    };

    AktieDataService.update(this.state.currentAktie.id, data)
      .then(response => {
        this.setState(prevState => ({
          currentAktie: {
            ...prevState.currentAktie,
            published: status
          }
        }));
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updateAktie() {
	AktieDataService.update(
      this.state.currentAktie.id,
      this.state.currentAktie
    )
      .then(response => {
        console.log(response.data);
        this.setState({
          message: "The Aktie was updated successfully!"
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  deleteAktie() {
    AktieDataService.delete(this.state.currentAktie.id)
      .then(response => {
        console.log(response.data);
        this.props.router.navigate('/aktienliste');
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { currentAktie } = this.state;

    return (
      <div>
        {currentAktie ? (
          <div className="edit-form">
            <h4>Aktie</h4>
            <form>
              <div className="form-group">
                <label htmlFor="title">Name</label>
                <input id="title" type="text"
                  className="form-control"
                  value={currentAktie.Name}
                  onChange={this.onChangeName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Symbol</label>
                <input id="description"
                  type="text"
                  className="form-control"
                  value={currentAktie.Symbol}
                  onChange={this.onChangeSymbol}
                />
              </div>

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentAktie.published ? "Published" : "Pending"}
              </div>
            </form>

            {currentAktie.published ? (
              <button
                className="badge badge-primary mr-2"
                onClick={() => this.updatePublished(false)}
              >
                UnPublish
              </button>
            ) : (
              <button
                className="badge badge-primary mr-2"
                onClick={() => this.updatePublished(true)}
              >
                Publish
              </button>
            )}

            <button
              className="badge badge-danger mr-2"
              onClick={this.deleteAktie}
            >
              Delete
            </button>

            <button
              type="submit"
              className="badge badge-success"
              onClick={this.updateAktie}
            >
              Update
            </button>
            <p>{this.state.message}</p>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Aktie...</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Aktie);

import React, { Component } from "react";
import { withRouter } from '../common/with-router';
import styleInfoPanel from "./infopanel.module.css";

class InfoPanel extends Component {
	constructor(props) {
	    super(props);

	    this.state = {};
	}

	componentDidMount() {
		// Beim Start
	    //this.getAktie(this.props.router.params.id);
	}

	render() {
	    return this.props.text?(
				<div className={styleInfoPanel.infopanel} >
					{this.props.text}
				</div>
			):(
				<></>
			);
	}
}

export default withRouter(InfoPanel);

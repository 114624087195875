import React, { Component } from "react";
import AktienDataService from "../services/aktien.service";
import FinanzdatenDataService from "../services/finanzdaten.service";
//import { Link } from "react-router-dom";
//import styleAktie from "./aktie.module.css"
import InfoPanel from "../components/infopanel.component";
import AktieInfo from "./aktieInfo.component";
import styleAS from "./aktienSuche.module.css"
import * as ReactBootstrap from 'react-bootstrap';


export default class AktienList extends Component {
	constructor(props) {
		super(props);
		this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
		this.retrieveAktien = this.retrieveAktien.bind(this);
		this.refreshList = this.refreshList.bind(this);
		this.setActiveAktie = this.setActiveAktie.bind(this);
		this.searchTitle = this.searchTitle.bind(this);
		this.onKeyup = this.onKeyup.bind(this);

		this.state = {
			suchAktien: [],
			selektierteAktie: null,
			currentIndex: -1,
			searchTitle: "",
			laedDaten:false
		};

	}

	componentDidMount() {
		// Nachdem Komponente angezeigt wird
		//this.retrieveAktien();
	}

	onChangeSearchTitle(e) {
		const searchTitle = e.target.value;

		this.setState({
		  searchTitle: searchTitle
		});
	}

	retrieveAktien() {
		AktienDataService.getAll()
		  .then(response => {
		    this.setState({
		      suchAktien: response.data
		    });
		    console.log(response.data);
		  })
		  .catch(e => {
		    console.log(e);
		  });
	}

	refreshList() {
	    this.retrieveAktien();
	    this.setState({
	      selektierteAktie: null,
	      currentIndex: -1
	    });
	}

	setActiveAktie(aSuchAktie, index) {
		let _f = 'setActiveAktie';
        console.log(`${_f} index=${index} `);
	    this.setState({
			selektierteAktie: aSuchAktie,
	    	currentIndex: index,
			laedDaten: true
	    });
		if (!aSuchAktie)
			return;
        console.log(`${_f} Abrufen ${aSuchAktie.symbol}`);
	    //AktienDataService.getAlphavantageGetInfo(this.state.selektierteAktie['1. symbol'])
	    FinanzdatenDataService.wallmineGetInfo(aSuchAktie['symbol'], aSuchAktie['exchange'])
	      .then(response => {
	        this.setState({
				selektierteAktie: response.data,
	          	//suchAktien: response.data
				laedDaten: false
	        });
	        console.log(response.data);
	      })
	      .catch(e => {
	        console.log(e);
	      });
	}


	searchTitle() {
		let _f = 'searchTitle';
		console.log(`${_f} 1`);
		this.setState({
		  selektierteAktie: null,
		  currentIndex: -1
		});
		console.log(`${_f} 2`);
		//AktienDataService.getAlphavantageSearch(this.state.searchTitle)
		FinanzdatenDataService.wallmineSuche(this.state.searchTitle)
		  .then(response => {
			console.log(`${_f} 3`);
		    this.setState({
		      suchAktien: response.data
		    });
		    console.log(response.data);
		  })
		  .catch(e => {
		    console.log(e);
		  });
	}

	onKeyup(e) {
		if (e.key === 'Enter') {
			console.log('Enter in Input gedrückt');
			this.searchTitle();
		}
	}

	render() {
	    const { searchTitle, suchAktien, selektierteAktie, currentIndex } = this.state;

	    return (
	      <div className="list row">
	        <div className="col-md-8">
	          <div className="input-group mb-3">
	            <input
	              type="text"
	              className="form-control"
	              placeholder="WKN, ISIN oder Name"
	              value={searchTitle}
	              onChange={this.onChangeSearchTitle}
				  onKeyUp={this.onKeyup}
	            />
	            <div className="input-group-append">
	              <button
	                className="btn btn-outline-secondary"
	                type="button"
	                onClick={this.searchTitle} >
	                suchen
	              </button>
	            </div>
	          </div>
	        </div>
	        <div className="col-md-6">
	          <h4>Liste der Wallmine Suchergebnisse</h4>

			  {(!suchAktien || !suchAktien.length === 0) ? (
				<><InfoPanel text="Kein Suchergebnis!" /></> ):( <></> )
			  }
			  {(suchAktien && suchAktien.message) ? (
				<><InfoPanel text={suchAktien.message} /></> ):( <></> )
			  }
			  <div className="col-md-6">
				  {selektierteAktie ? (
				    <div>
						<AktieInfo aktie={this.state.selektierteAktie} />
						{this.state.laedDaten?<ReactBootstrap.Spinner animation="border" />:<></>}
				    </div>
				  ) : (
				    <div>
				      <br />
				      <p>Please click on a Tutorial...</p>
				    </div>
				  )}
			  </div>
			  <div className={[styleAS.scrollEins, styleAS.scrollbarDeepPurple,
								styleAS.borderedDeepDurple, styleAS.thin].join(' ')}>
		          <ul className="list-group" onChange={this.onChangeActiveAktie} >
		            {suchAktien && suchAktien.liste &&
		              suchAktien.liste.map((aSuchAktie, index) => (
		                <li
		                  className={
		                    "list-group-item " +
		                    (index === currentIndex ? "active" : "")
		                  }
						  onClick={() => this.setActiveAktie(aSuchAktie, index)	}
		                  key={index}
		                >
		                  {aSuchAktie['name']}<br/>
		                  <blau>{aSuchAktie['symbol']} </blau><gruen>{aSuchAktie['exchange']}</gruen>
		                </li>
		              ))}
		          </ul>
			  </div>

	        </div>
	      </div>
	    );
	} //render
}

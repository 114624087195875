/*
siehe https://github.com/negomi/react-burger-menu
*/
import React from 'react';
import imgBurger from "./img/burger.png"

import { slide as Menu } from 'react-burger-menu'

class Sidebar extends React.Component {
	showSettings (event) {
		event.preventDefault();
	}

  	render () {
		var styles = {
		  bmBurgerButton: {
		    //position: 'fixed',
		    width: '36px',
		    height: '30px',
		    left: '36px',
		    top: '36px'
		  },
		  bmBurgerBars: {
		    background: '#373a47'
		  },
		  bmBurgerBarsHover: {
		    background: '#a90000'
		  },
		  bmCrossButton: {
		    height: '24px',
		    width: '24px'
		  },
		  bmCross: {
		    background: '#bdc3c7'
		  },
		  bmMenuWrap: {
		    position: 'fixed',
		    height: '100%'
		  },
		  bmMenu: {
		    background: '#373a47',
		    padding: '2.5em 1.5em 0',
		    fontSize: '1.15em'
		  },
		  bmMorphShape: {
		    fill: '#373a47'
		  },
		  bmItemList: {
		    color: '#b8b7ad',
		    padding: '0.8em'
		  },
		  bmItem: {
		    display: 'inline-block'
		  },
		  bmOverlay: {
		    background: 'rgba(0, 0, 0, 0.3)'
		  },
		  bmIcon: {
			background: 'aliceblue',
			borderRadius: '5px'
		  },
		  menuItem: {
			display: 'block !important',
			height: '2em'
		  }
		}
		let itmStyle = {
			display: 'block',
			height: '1em'
		}
		// NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
		return (
			<Menu styles={ styles } customBurgerIcon={ <img src={imgBurger} alt='#' /> }  >
				<a id="home" style={itmStyle} href="/">Home</a><br/>
				<a id="aktiensuche" style={itmStyle} href="/aktiensuche">Aktiensuche</a><br/>
				<a id="aktienliste" style={itmStyle} href="/aktienliste">Aktienliste</a><br/>
				<a id="tutorials" style={itmStyle} href="/tutorials">Tutorials</a><br/>
			</Menu>
		);
	}
}

export default Sidebar;

import React, { Component } from "react";
import AktienDataService from "../services/aktien.service";
import { Link } from "react-router-dom";
import styleAktie from "./aktie.module.css"
//import themeAktienListe from "./aktienListe.module.css"



export default class AktienList extends Component {
	constructor(props) {
	    super(props);
	    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
	    this.retrieveAktien = this.retrieveAktien.bind(this);
	    this.refreshList = this.refreshList.bind(this);
	    this.setActiveTutorial = this.setActiveTutorial.bind(this);
	    this.removeAllAktien = this.removeAllAktien.bind(this);
	    this.searchTitle = this.searchTitle.bind(this);

	    this.state = {
	      aktien: [],
	      currentTutorial: null,
	      currentIndex: -1,
	      searchTitle: ""
	    };

		this.info_kgv = `
Die Gewinnrendite ist der Kehrwert des KGV in Prozent ausgedrückt, also der Gewinn je
Aktie geteilt durch den Aktienkurs.[11] Die Gewinnrendite kann somit als Verzinsung der
Aktie interpretiert werden.[12][13] Das KGV des Aktienmarkts wird auch vom herrschenden
Zinsniveau beeinflusst.[14] Da Kapitalanleger Anlagemöglichkeiten mit höchster Rendite
bevorzugen, kommt es zu einem Ausgleich der Renditen nach dem Gesetz von Angebot und
Nachfrage durch sogenannte Arbitrage-Geschäfte. Der Kurswert der Aktien passt sich demnach
so an, dass der Kehrwert des KGV sich am herrschenden Zinssatz, bereinigt um Risikoaufschläge,
orientiert.[15]
Senkt die Zentralbank den Leitzins, so führt dies mit einer gewissen Zeitverzögerung ceteris
paribus zu steigenden KGV: die Aktienkurse steigen. Wird der Leitzins angehoben, so sinken
dementsprechend die KGV und damit die Aktienkurse.[16]
Zusätzlich kann der Zinssatz durch die Fremdkapitalkosten die Gewinne des Unternehmens und
damit auch das KGV beeinflussen und den oben erläuterten Effekt verstärken.[17]
		`;
	this.info_kbv = `
Eine traditionelle Theorie des Value Investing besagt, dass eine Aktie umso
preiswerter ist, je niedriger ihr KBV ist, und dass ihr fairer Wert in etwa
dem Buchwert entspricht.
Das KBV berücksichtigt jedoch lediglich den in der Bilanz ausgewiesenen Buchwert;
stille Reserven und stille Lasten sind nicht enthalten. Insbesondere bei
Beteiligungs- und Immobiliengesellschaften gibt es daher oft einen verfälschten Wert wieder.
		`;
	}

	componentDidMount() {
    	this.retrieveAktien();
	}

	onChangeSearchTitle(e) {
	    const searchTitle = e.target.value;

	    this.setState({
	      searchTitle: searchTitle
	    });
	}

	retrieveAktien() {
	    AktienDataService.getAll()
	      .then(response => {
	        this.setState({
	          aktien: response.data
	        });
	        console.log(response.data);
	      })
	      .catch(e => {
	        console.log(e);
	      });
	}

	refreshList() {
	    this.retrieveAktien();
	    this.setState({
	      currentTutorial: null,
	      currentIndex: -1
	    });
	}

	setActiveTutorial(tutorial, index) {
	    this.setState({
	      currentTutorial: tutorial,
	      currentIndex: index
	    });
	}

	removeAllAktien() {
	    AktienDataService.deleteAll()
	      .then(response => {
	        console.log(response.data);
	        this.refreshList();
	      })
	      .catch(e => {
	        console.log(e);
	      });
	}

	searchTitle() {
	    this.setState({
	      currentTutorial: null,
	      currentIndex: -1
	    });

	    AktienDataService.findByTitle(this.state.searchTitle)
	      .then(response => {
	        this.setState({
	          aktien: response.data
	        });
	        console.log(response.data);
	      })
	      .catch(e => {
	        console.log(e);
	      });
	}

	render() {
	    const { searchTitle, aktien, currentTutorial, currentIndex } = this.state;

	    return (
	      <div className="list row">
	        <div className="col-md-8">
	          <div className="input-group mb-3">
	            <input
	              type="text"
	              className="form-control"
	              placeholder="WKN, ISIN oder Name"
	              value={searchTitle}
	              onChange={this.onChangeSearchTitle}
	            />
	            <div className="input-group-append">
	              <button
	                className="btn btn-outline-secondary"
	                type="button"
	                onClick={this.searchTitle} >
	                suchen
	              </button>
	            </div>
	          </div>
	        </div>
	        <div className="col-md-6">
	          <h4>Liste der Suchergebnisse</h4>

			  {!aktien||aktien.length===0 ? (<>Kein Suchergebnis!</>):(<></>)}

	          <ul className="list-group" >
	            {aktien &&
	              aktien.map((aAktie, index) => (
	                <li
	                  className={
	                    "list-group-item " +
	                    (index === currentIndex ? "active" : "")
	                  }
	                  onClick={() => this.setActiveTutorial(aAktie, index)}
	                  key={index}
	                >
	                  {aAktie.Name}
						<daten style={{display:'none'}}>
							{JSON.stringify(aAktie)}
						</daten>
	                </li>
	              ))}
	          </ul>

	          <button
	            className="m-3 btn btn-sm btn-danger"
	            onClick={this.removeAllAktien} >
	            Alle löschen
	          </button>
	        </div>
	        <div className="col-md-6">
	          {currentTutorial ? (
	            <div>
	              <h4>Aktie</h4>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label><strong>Name:</strong></label>{" "}
	                {currentTutorial.Name}
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label><strong>Symbol:</strong></label>{" "}
	                {currentTutorial.Symbol}
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label title={this.info_kgv}>
	                  <b>KGV <blau>PER</blau>:</b>
	                </label>{" "}
	                {currentTutorial.PERatio}
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label title={this.info_kbv}>
	                  <b>KBV <blau>P/BR</blau>:</b>
	                </label>{" "}
	                {currentTutorial.PriceToBookRatio}
	              </div>

	              <Link
	                to={"/aktie/" + currentTutorial.id}
	                className="badge badge-warning"
	              >
	                Edit
	              </Link>
	            </div>
	          ) : (
	            <div>
	              <h4>Aktie</h4>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label><strong>Name:</strong></label>{" "}
	                Bitte einen Sucheintrag anklicken...
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label><strong>Symbol:</strong></label>{" "}
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label title={this.info_kgv}>
	                  <b>KGV <blau>PER</blau>:</b>
	                </label>{" "}
	              </div>
	              <div className={styleAktie.aktieeigenschaft} >
	                <label title={this.info_kbv}>
	                  <b>KBV <blau>P/BR</blau>:</b>
	                </label>{" "}
	              </div>

	            </div>
	          )}
	        </div>
	      </div>
	    );
	} //render
}

import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Sidebar from './Sidebar';

import AddTutorial from "./components/tutorial-add.component";
import Tutorial from "./components/tutorial.component";
import TutorialsList from "./components/tutorials-list.component";

import AktienSuche from "./components/aktienSuche.component";
import AktienListe from "./components/aktienListe.component";
import Aktie from "./components/aktie.component";


class App extends Component {
	constructor(props) {
    	super(props);
		this.state = {
      		//showNavExternal: false
    	};
	}

  	render() {
	    return (
	      <div>
		    <nav className="navbar navbar-expand navbar-dark bg-dark">
				<Sidebar />
				&nbsp;
				<Link to={"/aktiensuche"} className="navbar-brand">
					secius#Finanz
				</Link>
			</nav>
			<Breadcrumbs />

	        <div className="container mt-3">
	          <Routes>
	            <Route path="/" element={<AktienSuche/>} />
	            <Route path="/aktiensuche" element={<AktienSuche/>} />
	            <Route path="/aktienliste" element={<AktienListe/>} />
	            <Route path="/aktie/:id" element={<Aktie/>} />
	            <Route path="/tutorials" element={<TutorialsList/>} />
	            <Route path="/add" element={<AddTutorial/>} />
	            <Route path="/tutorials/:id" element={<Tutorial/>} />
	          </Routes>
	        </div>
	      </div>
	    );
  	} // render
}

export default App;

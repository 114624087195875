/*
Aktieninformationen anzeigen
*/
import React from 'react';
import styleAktieInfo from './aktieInfo.module.css';
//import { AppKontext } from "../App";
import AktieEigenschaft from './aktieEigenschaft.component';



export default function Auto({aktie}) {
	//const { stadium, setStadium } = React.useContext(AppKontext);

	return (
		<div className={styleAktieInfo.rahmen}>
			"{aktie.Name}"<br/>
			<AktieEigenschaft text="Branche" eigenschaft="Branche" aktie={aktie} />
			<AktieEigenschaft text="Metier" eigenschaft="Metier" aktie={aktie} />
			<AktieEigenschaft text="Buchwert" eigenschaft="BookValue" aktie={aktie} />
			<AktieEigenschaft text="Aktienanzahl" eigenschaft="ShareCount" aktie={aktie} />
			<AktieEigenschaft text="KGV" textEN="PER" eigenschaft="PERatio" aktie={aktie} />
			<AktieEigenschaft text="KBV" textEN="P/BR" eigenschaft="PriceToBookRatio" aktie={aktie} />

		</div>
	);
}

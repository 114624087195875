/*
Eine Aktieneigenschaft
*/
import React from 'react';
import styleAktieEigenschaft from './aktieEigenschaft.module.css';
//import { AppKontext } from "../App";
//import styleAktie from "./aktie.module.css"

export default function Auto({eigenschaft,aktie,title,text,textEN}) {
	//const { stadium, setStadium } = React.useContext(AppKontext);

	return (
        <div className={styleAktieEigenschaft.aktieeigenschaft} title={title}>
			<label>
				<b>{text?text:(eigenschaft?eigenschaft:'?')} <blau>{textEN?textEN:''}</blau></b>
			</label>
	        {aktie[eigenschaft]?aktie[eigenschaft]:'--'}
		</div>
	);
}

import http from "../http-common";

class FinanzdatenDataService {
	getAlphavantageSearch(aTitle) {
		return http.get(`/finanzdaten/alphavantageSearch?title=${aTitle}`);
	}

	getAlphavantageGetInfo(aSymbol) {
		return http.get(`/finanzdaten/alphavantageGetInfo?symbol=${aSymbol}`);
	}

	wallmineGetInfo(aSymbol,aExchange) {
		return http.get(`/finanzdaten/wallmineGetInfo?symbol=${aSymbol}&exchange=${aExchange}`);
	}

	wallmineSuche(aSuche) {
		return http.get(`/finanzdaten/wallmineSuche?suche=${aSuche}`);
	}

}

let _finanzdatenService = new FinanzdatenDataService();

export default _finanzdatenService;

import http from "../http-common";

class AktienDataService {
	getAlphavantageSearch(aTitle) {
		return http.get(`/aktien/alphavantageSearch?title=${aTitle}`);
	}

	getAlphavantageGetInfo(aSymbol) {
		return http.get(`/aktien/alphavantageGetInfo?symbol=${aSymbol}`);
	}

	getAll() {
		return http.get("/aktien");
	}

	get(id) {
		return http.get(`/aktien/${id}`);
	}

	create(data) {
		return http.post("/aktien", data);
	}

	update(id, data) {
		return http.put(`/aktien/${id}`, data);
	}

	delete(id) {
		return http.delete(`/aktien/${id}`);
	}

	deleteAll() {
		return http.delete(`/aktien`);
	}

	findByTitle(title) {
		return http.get(`/aktien?title=${title}`);
	}
}

let _aktienService = new AktienDataService();

export default _aktienService;
